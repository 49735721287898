/*
Das ist eine gute Idee allerdings bekomme ich sie nicht
via background-image: url('@{assetsPath}/images/icon--rightArrow.svg');
zum laufen

@paths : {
assets: "@/../../assets";
images: "@/../../assets/images";
}
*/
.background--grey {
  background-color: #2B2E34;
}
.background--blue {
  background-color: #0B2CC2;
}
.background--lighterBlue {
  background-color: #1478C2;
}
.background--gold {
  background-color: #C47806;
}
.background--lightGold {
  background-color: #C4950B;
}
.background--pink {
  background-color: #DD2C6C;
}
.background--white {
  background-color: #ffffff;
}
.color--grey {
  color: #2B2E34;
}
.color--blue {
  color: #0B2CC2;
}
.color--lighterBlue {
  color: #1478C2;
}
.color--gold {
  color: #C47806;
}
.color--lightGold {
  color: #C4950B;
}
.color--pink {
  color: #DD2C6C;
}
.color--white {
  color: #ffffff;
}
@media (min-width: 1023px ) {
}@media (max-width: 1023px ) {
}@media (min-width: 1023px ) {
  .SearchResults__AbMainHeadline {
    padding-top: 4.5em;
  }
}
@media (max-width: 1023px ) {
  .SearchResults__AbMainHeadline {
    padding-top: 4.5em;
  }
}
@media (max-width: 750px ) {
  .SearchResults__AbMainHeadline {
    padding-top: 3.5em;
    padding-bottom: 0;
  }
}
.SearchResults__group {
  display: grid;
  position: relative;
  margin: 1em auto;
}
.SearchResults__group > * {
  min-height: 0;
  min-width: 0;
}
@media (min-width: 1023px ) {
  .SearchResults__group {
    grid-column-gap: 24px;
  }
}
@media (max-width: 1023px ) {
  .SearchResults__group {
    grid-column-gap: 20px;
  }
}
@media (max-width: 750px ) {
  .SearchResults__group {
    grid-column-gap: 10px;
  }
}
@media (min-width: 1023px ) {
  .SearchResults__group {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
}
@media (max-width: 1023px ) {
  .SearchResults__group {
    grid-template-columns: repeat(8, 1fr);
  }
}
@media (max-width: 750px ) {
  .SearchResults__group {
    grid-template-columns: repeat(4, 1fr);
  }
}
.SearchResults__groupTitle {
  margin: 1em 0;
}
@media (min-width: 1023px ) {
  .SearchResults__groupTitle {
    grid-column: 2 / span 10;
  }
}
@media (max-width: 1023px ) {
  .SearchResults__groupTitle {
    grid-column: 1 / span 8;
  }
}
@media (max-width: 750px ) {
  .SearchResults__groupTitle {
    grid-column: 1 / span 4;
  }
}
@media (min-width: 1023px ) {
  .SearchResults__groupEmpty,
  .SearchResults__groupFetching,
  .SearchResults__groupResult {
    grid-column: 3 / span 8;
  }
}
@media (max-width: 1023px ) {
  .SearchResults__groupEmpty,
  .SearchResults__groupFetching,
  .SearchResults__groupResult {
    grid-column: 2 / span 7;
  }
}
@media (max-width: 750px ) {
  .SearchResults__groupEmpty,
  .SearchResults__groupFetching,
  .SearchResults__groupResult {
    grid-column: 1 / span 4;
  }
}
